.headlineContainer {
	flex-flow: column;
	justify-content: flex-end;
	align-items: flex-start;
	min-height: 500px;
	padding: 74px 32px 40px;
	display: flex;
	position: relative;
}

.headline {
	color: #000;
	max-width: 15em;
	margin-top: 0;
	margin-bottom: 0;

	font-size: 4.5rem;
	font-weight: 650;
	line-height: 1;
}
.heroImageContainer {
	width: 100%;
	overflow: hidden;
	max-height: 1280px;
	/* min-height: 980px; */
	height: min(90dvh, min-content);
	height: min-content;
}

.heroImage {
	width: 100%;
}

.bodyContent {
	/* grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr; */
	padding: 120px 64px;
	max-width: 1440px;
	margin: 0 auto;
	/* display: grid; */
}
.bodyContent .textB {
	color: #000;
	font-size: 1.75rem;
	line-height: 2.25rem;
}
.bodyContent p,
.bodyContent .bodyImage,
.gallery {
	margin-bottom: 2rem;
}
.gallerySideBySide {
	display: flex;
	align-items: stretch;
	justify-content: stretch;
	flex-direction: row;

	width: 100%;
	gap: 1.25rem;
}

.gallerySideBySide img {
	height: auto;
	flex-grow: 1;
	width: 0;
}

.galleryImageGrid {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;
	gap: 1.25rem;
	height: 1024px;

	overflow: hidden;
}
.galleryImageGrid .imageContainer {
	flex-basis: calc(50% - 1.25rem);
	flex-grow: 1;
	/* border: 1px solid red; */
	overflow: hidden;
	position: relative;
}

.galleryImageGrid img {
	object-fit: cover;
	width: auto;
	height: 100%;
	flex-basis: 50%;
	flex-grow: 1;
	position: absolute;
}

.galleryFullWidthCarousel {
	width: 100%;
	/* overflow: visible; */
}
.galleryFullWidthCarouselCommandCentre {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}
.galleryFullWidthCarouselCommandCentre span {
	text-decoration: underline;
	color: rgb(192, 90, 240);
}

.fullWidthCarouselContents {
	width: auto;
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	gap: 1.25rem;
	height: 100%;
	transition: transform 0.3s cubic-bezier(0.27, 0.08, 0.68, 0.9);
}

.fullWidthCarouselContents .imageContainer {
	display: contents;
	width: auto;
	/* max-width: 100%; */
	/* max-height: 768px; */
}

.fullWidthCarouselContents img {
	width: auto;
	max-width: max-content;
	max-height: 768px;

	display: block;
}

.videoPlayer {
	aspect-ratio: 16/9;
}

@media (max-width: 1068px) {
	.fullWidthCarouselContents {
		/* max-height: 512px; */
	}

	.fullWidthCarouselContents img {
		max-height: 512px;
	}

	.galleryImageGrid {
		height: 640px;
		gap: 1rem;
	}

	.gallerySideBySide {
		gap: 1rem;
	}
}

@media (max-width: 734px) {
	.galleryFullWidthCarousel {
		height: 100%;
	}

	.fullWidthCarouselContents img {
		height: 100%;
	}

	.galleryImageGrid {
		height: 384px;
		gap: 0.75rem;
	}
	.gallerySideBySide {
		gap: 0.75rem;
		flex-direction: column;
	}

	.gallerySideBySide img {
		width: 100%;
		flex-grow: 1;
		 
	}
}
